import { computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'

export default function userContinueHook () {

  const store = useStore()

  const langText = computed(() => store.state.login.langText)
  const isAsComponent = computed(() => store.state.login.isAsComponent)
  const newUIStates = computed(() => store.state.login.newUIStates)
  const accountList = computed(() => store.state.login.accountList)
  const preloadData = computed(() => store.state.login.preloadData)
  const online = computed(() => store.state.login.online)
  const newPrivacyAbt = computed(() => store.state.login.newPrivacyAbt)
  const defaultLocation = computed(() => store.state.login.defaultLocation)
  const relatedAcc = computed(() => store.state.login.relatedAcc)
  const phoneAbt = computed(() => store.state.login.phoneAbt)
  const commonAbt = computed(() => store.state.login.commonAbt)
  const isInRelatedAcc = computed(() => store.state.login.relatedAcc?.global)
  const LoginButtonPoint = computed(() => store.state.login.LoginButtonPoint)
  const areaCode = computed(() => store.state.login.areaCode)
  const loginPopConfig = computed(() => store.state.login.loginPopConfig)
  const filterAreaCodeTip = computed(() => store.state.login.filterAreaCodeTip)
  const showAreadCode = computed(() => store.state.login.showAreadCode)
  const publicLocalData = computed(() => store.state.login.publicLocalData)
  const showPointRetainModal = computed(() => store.state.login.showPointRetainModal)
  
  const isShowContinue = computed(() => {
    
    const { isRelation } = newUIStates.value
    if(isRelation) return true
    
    if(commonAbt.value?.AccountManager != 'on'){
      return true
    }
    return !accountList.value.length
  })


  const isSupportPhone = computed(() => {
    const phoneLoginEntrance = phoneAbt.value?.phoneLoginEntrance == 'on' || phoneAbt.value?.phoneRegisterEntrance == 'on' || false
    return phoneLoginEntrance
  })

  const aliasInputText = computed(() => {
    if (isInRelatedAcc.value && relatedAcc.value.abt == 'related') {
      return relatedAcc.value?.type == 'email' ? langText.value?.SHEIN_KEY_PWA_21280 : langText.value?.SHEIN_KEY_PWA_21302
    }
    if (!isSupportPhone.value) {
      return langText.value.SHEIN_KEY_PWA_15325
    }
    return langText.value.SHEIN_KEY_PWA_34381
  })

  const whtasAppType = computed(() => {
  // 判断上次使用渠道， 1 为sms，2位whatsapp
    const parent = typeof window != 'undefined' ? window : {}
    const isUsed = parent?.localStorage?.getItem('l_p_s_c_used') == '2'
    if (commonAbt.value?.WhatsAppSetup?.default == 'WhatsApp') {
      return { type: ['primary'], class: 'orderIndex', isUsed, }
    }
    return { type: ['default'], class: 'orderSecond', isUsed }
  })

  const smsType = computed(() => {
  // 判断上次使用渠道， 1 为sms，2位whatsapp
    const parent = typeof window != 'undefined' ? window : {}
    const isUsed = parent?.localStorage?.getItem('l_p_s_c_used') == '1'
    if (commonAbt.value?.WhatsAppSetup?.default == 'sms') {
      return { type: ['primary'], class: 'orderIndex', isUsed, }
    }
    return { type: ['default'], class: 'orderSecond', isUsed }
  })
  
  return { langText, isAsComponent, newUIStates, isShowContinue, preloadData, online, newPrivacyAbt, defaultLocation, relatedAcc, commonAbt, publicLocalData, accountList, isInRelatedAcc, LoginButtonPoint, areaCode, loginPopConfig, showAreadCode, showPointRetainModal, filterAreaCodeTip, isSupportPhone, aliasInputText, whtasAppType, smsType }

} 
