import { getMixPrivacyInfo } from '../service'
import { getRegisterData } from '@login/services/newUer'
import { getApplyForText } from 'public/src/pages/components/ccc/components/coupon/tools/getApplyForText.js'
const { SiteUID } = gbCommonInfo

const actions = {
  async setPrefetchData (store, data) {
    const { PreloadAbt = {}, PreloadData = {} } = data
    // abt 设置
    // 手机注册
    if (PreloadAbt?.phoneRegisterLogin) store.commit('setPhoneAbt', PreloadAbt?.phoneRegisterLogin?.p || '')
    store.commit('setCommonAbt', { key: 'AccountManager', val: PreloadAbt?.AccountManager?.param?.AccountManager || 'off' })
    // 站点切换
    if (PreloadAbt?.LoginSwtichsite) store.commit('setSwitchSiteAbt', PreloadAbt?.LoginSwtichsite?.p || '')
    // wathsapp
    const WhatsAppSetupAbt = { 
      show: PreloadAbt?.WhatsAppSetup?.p?.indexOf('show=on') > -1 ? 'on' : 'off',
      default: PreloadAbt?.WhatsAppSetup?.p?.indexOf('default=on') > -1 ? 'WhatsApp' : 'sms',
    }
    if (PreloadAbt?.WhatsAppSetup) store.commit('setCommonAbt', { key: 'WhatsAppSetup', val: WhatsAppSetupAbt })

    if(PreloadAbt?.ThirtyLogin) {
      store.commit('setFuseAbtResult', { key: 'fb', val: PreloadAbt?.ThirtyLogin?.param?.indexOf('facebook=on') >= 0 ? true : false })
      store.commit('setFuseAbtResult', { key: 'google', val: PreloadAbt?.ThirtyLogin?.param?.indexOf('google=on') >= 0 ? true : false })
      store.commit('setFuseAbtResult', { key: 'vk', val: PreloadAbt?.ThirtyLogin?.param?.indexOf('vk=on') >= 0 ? true : false })
      store.commit('setFuseAbtResult', { key: 'line', val: PreloadAbt?.ThirtyLogin?.param?.indexOf('line=on') >= 0 ? true : false })
      store.commit('setFuseAbtResult', { key: 'kakao', val: PreloadAbt?.ThirtyLogin?.param?.indexOf('kakao=on') >= 0 ? true : false })
      store.commit('setFuseAbtResult', { key: 'naver', val: PreloadAbt?.ThirtyLogin?.param?.indexOf('naver=on') >= 0 ? true : false })
    }
    store.dispatch('setPageShowType', PreloadData)
    store.commit('setPreloadData', PreloadData)
    // line
    store.commit('setCommonAbt', { key: 'lineLogin', val: PreloadAbt?.LineLogin?.param || 'off' })
    // naver
    store.commit('setCommonAbt', { key: 'naverLogin', val: PreloadAbt?.NaverLogin?.param?.NaverLogin || 'no' })

    // 新人权益
    store.commit('setCommonAbt', { key: 'NewUserRights', val: PreloadAbt.NewUserRights?.p?.indexOf('showNewUserRights=on') > -1 ? true : false })

    store.commit('setCommonAbt', { key: 'ProtectedTips', val: PreloadAbt?.ProtectedTips?.param?.continue || 'yes' })

    store.commit('setCommonAbt', { key: 'LoginRegisterRetian', val: PreloadAbt?.LoginRegisterRetian?.param?.LoginRegisterRetian || 'off' })

    store.commit('setCommonAbt', { key: 'LoginRegisterRetianPops', val: PreloadAbt?.LoginRegisterRetianPops?.param?.LoginRegisterRetianPops || '' })

    store.commit('setCommonAbt', { key: 'PhoneZeroFiltered', val: PreloadAbt?.PhoneZeroFiltered?.param?.PhoneZeroFiltered || 'off' })

    store.commit('setCommonAbt', { key: 'PhoneAreaCodePopop', val: PreloadAbt?.PhoneAreaCodePopop?.param?.PhoneAreaCodePopop || 'old' })

    store.commit('setCommonAbt', { key: 'registerSuccess', val: PreloadAbt?.block?.param?.registerSuccess || 'default' })

    store.commit('setCommonAbt', { key: 'forgetPwdQA', val: PreloadAbt?.ForgetPwdQA?.param?.ForgetPwdQA === 'qa' })

    store.commit('setCommonAbt', { key: 'FBMessengerConnect', val: PreloadAbt?.FBMessengerConnect?.param?.FBMessengerConnect || 'no' })

    store.commit('setCommonAbt', { key: 'LoginPageIncentive', val: PreloadAbt?.LoginPageIncentive?.param || false })

    store.commit('setCommonAbt', { key: 'EmailLoginSubscribe', val: PreloadAbt?.EmailLoginSubscribe?.param?.EmailLoginSubscribe || 'no' })

    // WhatsApp 订阅
    store.commit('setCommonAbt', {
      key: 'SubscribeWhatsApp',
      val: {
        show: PreloadAbt?.SubscribeWhatsApp?.param != 'off' ? true : false,
      },
    })
    store.commit('setCommonAbt', {
      key: 'SignupSubscribeNote',
      val: PreloadAbt?.SignupSubscribeNote?.param == 'on',
    })
    // 公共参数
    store.commit('setPublicLocalData', PreloadData?.publicLocalData || {})
    // 新版隐私政策协议
    let param = PreloadAbt['newPrivacy']?.param || 'off'
    store.commit('setNewPrivacyAbt', { param, loading: false, origin: param })
    if (typeof window !== 'undefined' && param == 'mix'){
      await store.dispatch('initMixPrivacyInfo')
    }
  },
  async initMixPrivacyInfo(store) {
    const { privacyType, id, issupport: ipIsInSite } = await getMixPrivacyInfo()
    store.commit('setDefaultLocation', { ipIsInSite, originPrivacyType: privacyType })
    if(privacyType == 1){
      // 强合规
      store.commit('setNewPrivacyAbt', { param: 'yes', loading: false })
      store.commit('setDefaultLocation', { forceId: id })
      return
    }
    store.commit('setNewPrivacyAbt', { param: 'no', loading: false })
  },
  async setRegisterPopData (store) {
    const { code, firstCoupon, couponDetail, recommendInfo, language, point } = await getRegisterData()
    if(code == 0){
      const couponData = firstCoupon
      const productList = recommendInfo
      couponData.desc = getApplyForText(couponDetail, language, SiteUID)
      store.commit('setSuccessPopConfig', { couponData, productList, point })
      if(productList.length > 3) {
        store.commit('assignState', { isNewRegisterPop: true })
      }
    }
  },
  setPageShowType (store, data) {
    const { historyAccountList = [] } = data || {}
    if(historyAccountList?.length >= 1 && store.state?.commonAbt?.AccountManager == 'on') {
      store.commit('assignState', { accountList: historyAccountList })
      return 
    }
  }
}


export default actions
