import { daEventCenter } from 'public/src/services/eventCenter/index'
import analysisIndex from 'public/src/pages/login/analysis/index'
const { loginPageFrom } = analysisIndex
daEventCenter.addSubscriber({ modulecode: '2-8' })


export const exposeEmailLoginSubcribeCheckbox = (type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_email_login_subcribe_checkbox',
      subcribe_type: type ? 'default_not_subcribe' : 'default_subcribe'
    },
  })
}

export const exposeTermsConditions = (location) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_terms_conditions',
      show_location: location,
      login_from: loginPageFrom()
    },
  })
}
export const exposePrivacyCookiesPolicy = (location) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_privacy_cookies_policy',
      show_location: location,
      login_from: loginPageFrom()
    },
  })
}
