<template>
  <div
    class="page__login-newUI-head"
    :class="{'newUi-head__network-error': !online}"
  >
    <sui_icon_nav_back_24px
      v-show="showback"
      class="icon-back"
      :is-rotate="publicLocalData.GB_cssRight"
      size="24px" 
      @click="back"
    />

    <!-- 无网提示 -->
    <ClientOnly>
      <s-network-error-alert
        class="newUi-head__error-tips"
        :description="langText.SHEIN_KEY_PWA_31743"
        @online-change="status => online = status.online"
      />
    </ClientOnly>

    <div
      v-show="showLogo"
      class="logo"
    >
      <SheinLogoIcon
        v-if="!publicLocalData.IS_RW"
        class="icon-sheinlogo"
      />
      <RomweLogoIcon
        v-if="publicLocalData.IS_RW" 
        class="login-logo-rw"
      />
      <strong
        v-if="counrtyCode"
        class="country-code not-fsp-element"
      >{{ counrtyCode }}</strong>
    </div>
    <ProtectedTips v-if="commonAbt?.ProtectedTips == 'yes' && type == 'continue'" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils'
import SheinLogoIcon from '@login/components/Icon/SheinLogoIcon.vue'
import RomweLogoIcon from '@login/components/Icon/RomweLogoIcon.vue'
import { sui_icon_nav_back_24px } from '@shein-aidc/icon-vue2'
import ProtectedTips from '@login/views/NewMainUI/components/ProtectedTips.vue'
import ClientOnly from 'vue-client-only'

defineProps({
  showback: { type: Boolean, default: false },
  showLogo: { type: Boolean, default: true },
  type: {
    type: String,
    default: ''
  }
})

const store = useStore()
const emit = defineEmits(['back'])
const publicLocalData = computed(() => {
  return store.state.login.publicLocalData || {}
})


const counrtyCode = computed(() => {
  if (['m', 'rwm'].includes(publicLocalData.value?.SiteUID)) return ''
  return store.state.login.defaultLocation?.value || ''
})
const commonAbt = computed(() => store.state.login.commonAbt)

const langText = computed(() => {
  return store.state.login.langText || {}
})

const online = computed({
  get () {
    return store.state.login.online || ''
  },
  set (val) {
    store.commit('login/assignState', { online: val })
  }
})

const back = () => {
  emit('back')
}

// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'NewMainHead'
}
</script>

<style lang="less">
.page__login-newUI-head {
  padding-top: 33px;
  position: relative;
  .icon-back {
    position: absolute;
    top: 5px;
    .left(-10px);
    color: #333;
    text-decoration: none;
    font-size: 24px;
    &:active, &:focus, &:hover {
      text-decoration: none;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    & when (@IS_RW) {
      padding: 0;
    }
    .icon-sheinlogo {
      width: 122.5px;
      height: 21px;
    }
    .login-logo-rw {
      width: 148px;
      height: 25px;
    }
    strong {
      font-size: 12px;
      font-weight: bold;
      align-self: flex-end;
      line-height: 1;
      position: relative;
      .left(-8px);
      bottom: -1px;
      & when (@IS_RW) {
        .left(-6px);
        bottom: -1px;
      }
    }
  }
}
.newUi-head__network-error {
  padding-top: calc(33px + 1.28rem);
  .icon-back {
    top: 1.28rem;
  }
  .newUi-head__error-tips {
    position: fixed;
    width: 10rem;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateZ(1px);
  }
}
</style>
